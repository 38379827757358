import React from 'react';
import Carousel from "react-material-ui-carousel";
import autoBind from "auto-bind"
import './ImageCarousel.css';
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Grid,
    Button
    
} from '@mui/material';

// example

function Banner(props) {
    if (props.newProp) console.log(props.newProp)
    const contentPosition = props.contentPosition ? props.contentPosition : "left"
    const totalItems = props.length ? props.length : 2;
    const mediaLength = totalItems - 1;

    let items = [];
    const content = (
        <Grid item xs={12 / totalItems} key="content">
            <CardContent className="Content">
                <Typography className="Title">
                    {props.item.Name}
                </Typography>

                <Typography className="Caption">
                    {props.item.Caption}
                </Typography>

                <Button variant="outlined" className="ViewButton" href="/about">
                    Learn more
                </Button>
            </CardContent>
        </Grid>
    )


    for (let i = 0; i < mediaLength; i++) {
        const item = props.item.Items[i];

        const media = (
            <Grid item xs={12 / totalItems} key={item.Name}>
                <CardMedia
                    className="Media"
                    image={item.Image}
                    title={item.Name}
                >
                    <Typography className="MediaCaption">
                        {item.Name}
                    </Typography>
                </CardMedia>

            </Grid>
        )

        items.push(media);
    }

    if (contentPosition === "left") {
        items.unshift(content);
    } else if (contentPosition === "right") {
        items.push(content);
    } else if (contentPosition === "middle") {
        items.splice(items.length / 2, 0, content);
    }

    return (
        <>
        
        <Card raised className="Banner">
            <Grid container spacing={0} className="BannerGrid">
                {items}
            </Grid>
        </Card>
        </>
    )
}

const items = [
    {
        
        Caption: "Sex Education at Surulere Senior High School, Surulere",
        contentPosition: "left",
        Items: [
            {
               
                Image: "./images/impact6.jpg"
            },
            {
                
                Image: "./images/gyfbanner2.jpg"
            }
        ]
    },
    {
        
        Caption: "Leadership and goal-setting training at Surulere Community High School",
        contentPosition: "middle",
        Items: [
            {
               
                Image: "./images/impact3.jpg",
            },
            {
               
                Image: "./images/students5.jpg",
            }
        ]
    },
    {
        
        Caption: "Drug and substance abuse education",
        contentPosition: "right",
        Items: [
            {
               
                Image: "./images/impact5.jpg"
            },
            {
               
                Image: "./images/students5.jpg"
            }
        ]
    },
    {
        
        Caption: "Counseling session at Clegg High School",
        contentPosition: "left",
        Items: [
            {
               
                Image: "./images/impact.jpg"
            },
            {
               
                Image: "./images/impact.jpg"
            }
        ]
    }
]

class ImageCarousel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            autoPlay: true,
            timer: 500,
            animation: "slide",
            indicators: false,
            timeout: 500,
            navButtonsAlwaysVisible: false,
            navButtonsAlwaysInvisible: false
        }

        autoBind(this);
    }

    toggleAutoPlay() {
        this.setState({
            autoPlay: !this.state.autoPlay
        })
    }

    toggleIndicators() {
        this.setState({
            indicators: !this.state.indicators
        })
    }

    toggleNavButtonsAlwaysVisible() {
        this.setState({
            navButtonsAlwaysVisible: !this.state.navButtonsAlwaysVisible
        })
    }

    toggleNavButtonsAlwaysInvisible() {
        this.setState({
            navButtonsAlwaysInvisible: !this.state.navButtonsAlwaysInvisible
        })
    }

    changeAnimation(event) {
        this.setState({
            animation: event.target.value
        })
    }

    changeTimeout(event, value) {
        this.setState({
            timeout: value
        })
    }

    render() {
        return (
            <div style={{ paddingTop: "50px",marginTop: "50px",  paddingBottom: "1px", color: "#330064" }}>
                <h1 style={{textAlign: "center",  paddingBottom: "50px"}}>SOME CAUSES</h1>
            
                <Carousel
                    className="Example"
                    autoPlay={this.state.autoPlay}
                    timer={this.state.timer}
                    animation={this.state.animation}
                    indicators={this.state.indicators}
                    timeout={this.state.timeout}
                    navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}
                    navButtonsAlwaysInvisible={this.state.navButtonsAlwaysInvisible}
                    next={(now, previous) => console.log(`Next User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
                    prev={(now, previous) => console.log(`Prev User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
                    onChange={(now, previous) => console.log(`OnChange User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
                >
                    {
                        items.map((item, index) => {
                            return <Banner item={item} key={index} contentPosition={item.contentPosition} />
                        })
                    }
                </Carousel>

            </div>

        )
    }
}

export default ImageCarousel;