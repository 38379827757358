import React from 'react';
import './Footer.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link } from 'react-router-dom';

function Footer() {

  const today = new Date();
  const year = today.getFullYear();
  return (
    <section className="footer">
      <div className="footer__topContainer">
        <div className="footer__top">
          {/* <div className="footer__topLeft">
            <h5>Redemption Camp</h5>
            <p>Ogun State, Nigeria</p>
          </div> */}
          <div className="footer__topRight">
            <FacebookIcon />
            <TwitterIcon />
            <InstagramIcon />
            <YouTubeIcon />
          </div>
        </div>
      </div>

      <div className="footer__bottomContainer">
        <div className="footer__bottom">
          <div className="footer__bottomLeft">
            <Link to="/" className="footer__logo">
              <p>Gideon Youth<br />Foundation <br />
              <span style={{color:'white'}}>&copy; {year} </span> </p>
            </Link>
            
          </div>
          
          <div className="footer__bottomRight">
            <Link to="/">Home</Link>
            <Link to="about">About</Link>
            <Link to="ourImpact">Our Impact</Link>
          
            <Link to="contact">Contact</Link>
          </div>
        </div>
        <div style={{paddingTop: '10px', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
          {/* <h4 style={{color: 'white'}}>
            Designed with <span style={{color: 'red', fontSize: '15px'}}>♡
            </span> <a href="https://www.titisoft.com/" target="_blank"><span style={{color: '#A9D5EA'}}>Titisoft.com </span> </a> 
            </h4> */}
            <a href='https://titisoft.com' target='_blank'>
          <small style={{color:'#e8fccf'}}>Developed by BE Tosan <span style={{color:'white'}}>@ titisoft.com </span></small>
        </a> 
            
        </div>
      </div>
    </section>
  )
}

export default Footer;
