import React from 'react';
import PageHeader from '../PageHeader/PageHeader';
import './About.css';
import HeaderImage from '../PageHeader/images/who-we-are.jpg';
import SideBar from '../SideBar/SideBar';
import Image1 from './images/students.jpg';
//  lagos

function About() {
  return (
    <section className="about">
      <PageHeader image={HeaderImage} title="About Us" subTitle="Working To Create a Better Tomorrow" />

      <div className="about__main">
        <SideBar
          title="Our Work"
          description="We help disadvantaged young people escape a future of low self-esteem, drugs, poverty, and being excluded from society."
          list={[
            "SCHOOL-BASED DRUG AND SUBSTANCE PREVENTION PROGRAMS",
            "SEX AND SEXUALITY EDUCATION",
            "SPONSORING ACADEMICALLY GIFTED STUDENTS",
            "LEADERSHIP AND GOAL-SETTING TRAINING",
            "EDUCATION BEYOND THE FOUR WALLS"]}
          info="For more information on how we plan to make an impact across Lagos in particular, and Nigeria in general, go "
          link="/ourImpact" />

        <article className="about__article">
          <img src={Image1} alt="young person" />
          <h2 style={{fontSize: '24px', marginBottom: '20px', marginTop: '20px', color: '#330064'}}>Why we founded Gideon Youth Foundation</h2>
          <p style={{marginTop: '10px'}}>Gideon Youth Foundation was born out of deep compassion for the fate of the average public school student as most public schools fall short as places of learning and development.</p>
          <p style={{marginTop: '10px'}}>Overpopulated classrooms, dilapidated or non-existent infrastructure are just a few of the challenges facing our public school system. </p>
          <p style={{marginTop: '10px'}}>These challenges, coupled with the socio-economic difficulties of families who are forced to use the system, lead to low morale and poor outcomes for the students.</p>
          <p style={{marginTop: '10px'}}>It is in view of the aforementioned challenges that we have decided to step in the gap one school at a time.</p>
          <h2 style={{fontSize: '24px', marginBottom: '20px', marginTop: '20px',color: '#330064'}}>Background to our name, Gideon</h2>
          <p>Gideon was one of Israel’s leaders and his story is recorded in the book of Judges Chapters 6-8; he was potentially a great warrior who was said to be hiding away ‘threshing wheat in a winepress’.</p>
          <p style={{marginTop: '10px'}}>This can be interpreted to mean he was living below potential and not fulfilling purpose because a winepress, as the name suggests, is a place for processing grapes for wine and not for threshing wheat!</p>
          <p style={{marginTop: '10px'}}>He was in the wrong place using the wrong tools and probably not getting any substantial results.</p>
          <p style={{marginTop: '10px'}}>He was said to be hiding away from the Midianites who were the oppressors of Israel at that time i.e. the state of the nation had placed him in a position of fear.</p>
          <p style={{marginTop: '10px'}}>He was forced to use the wrong tools; the state of the nation was literally robbing him of his God given potential. This is what is observed with school age children roaming the streets, especially those who are supposed to be in secondary school.</p>
          <p style={{marginTop: '10px'}}>When they are on the streets instead of in their classrooms, they are in the wrong place and potentially developing the wrong skills for a fulfilling life.</p>
          <p style={{marginTop: '10px'}}>It is important to note that Gideon’s story changed as soon as he assumed his God-given role and this is what we must inspire our youth to do.</p>
          
        </article>
      </div>
      <div className="about__bumper"></div>

      <div className="about__main">
        <SideBar
          title="Helping Disadvantaged Young People"
          description="Gideon Youth Foundation provides partial or full assistance to poor students from secondary school to university levels, who are not in a position to continue their education because of financial hardships."
          desc="Areas of assistance include: "
          list={[
            "admission fees",
            "WAEC and JAMB/UTME registrations",
            " text books",
            "tuition fees",
            "exam fees",
            "hostel fees",
            "uniforms",
            "school supplies"

          ]}
          info="Please donate for these efforts, your donations will go a long way to build a bright future for the next generations in poor government schools in Lagos."
          link="/contact" />

        <article className="about__article">
          <h2>Our Mission</h2>
          <p>We inspire young minds by teaching them about personal effectiveness and leadership.</p>
          <p style={{marginTop: '10px'}}>We create awareness and strategies to avoid the pitfalls of drug abuse and premature sexual activity.</p>
          <p style={{marginTop: '10px'}}>We create an atmosphere of dignity for students to learn by providing furniture and refurbishing classrooms.</p>
          <p style={{marginTop: '10px'}}>We are committed to ensuring that deserving students from amongst our target group get the utmost support in their academic pursuits.</p>
          <p style={{marginTop: '10px'}}>We pay mandatory exam fees and offer scholarships to the most motivated students.</p>
        </article>
      </div>
    </section>
  )
}

export default About
