import React from 'react';
import './Hero.css';
import { Link } from 'react-router-dom';


function Hero() {
  return (
    <section className="hero" >
      <div className="hero__contentContainer">
        <div className="hero__content">
          <h1 >Inspiring Greatness!</h1>
          <h2 >We empower disadvantaged young students to achieve their full potential.</h2>
          <Link className="hero__callToAction" to="/about">LEARN MORE</Link>
        </div>
      </div>
    </section>
  )
}

export default Hero;
