import React from "react"
import styled from "styled-components"

const StyledParagraph = styled.p`
  color: white;
  line-height: 1.8;
`

export default function Address() {
  return (
    <div>
      <StyledParagraph>Redemption Camp</StyledParagraph>
      <StyledParagraph>Ogun State</StyledParagraph>
      <StyledParagraph>Nigeria</StyledParagraph>
    </div>
  )
}
