import React from 'react';
import './Registration.css';

function Registration() {
  return (
    <section className="registration">
      <h1>registration</h1>
    </section>
  )
}

export default Registration
