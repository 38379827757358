import React from "react";
import Header from "../Header/Header";
import Home from "../Home/Home";
import { Routes, Route } from "react-router-dom";
// import logo from '../../logo.svg';
// import { Counter } from '../../features/counter/Counter';
import "./App.css";
import About from "../About/About";
import OurImpact from "../OurImpact/OurImpact";
import Registration from "../Registration/Registration";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import ScrollToTop from "../../util/ScrollToTop";

function App() {
  return (
    <section>
      <ScrollToTop>
      <Header />
      <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/ourImpact" element={<OurImpact />}/>
            <Route path="/registration" element={<Registration />}/>
            <Route path="/contact" element={<Contact />}/>
            <Route path="/" element={<Home />}/>    
         
          
      </Routes> 
      <Footer />
      </ScrollToTop>
    </section>
  );
}

export default App;
