import React from 'react';
import styled from "styled-components"
import Container from "../container/Container"
import MainParagraph from "../Typography/MainParagraph"
import PageHeader from '../PageHeader/PageHeader';
import './Contact.css';
import HeaderImage from '../PageHeader/images/contact.jpg'
import Address from '../contactParts/Address'
import PhoneContact from '../contactParts/Phone'
import Email from '../contactParts/Email'


const StyledMainWrapper = styled.div`
  position: relative;
  width:100%;
  padding: 0;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc(50% - 360px);
    height: 100%;
    background: #330064;
    z-index: -1;
    @media (max-width: 1119px) {
      display: none;
    }
  }
`

const StyledMainGrid = styled.div`
  display: flex;
  flex-direction: row;

`
const StyledInfoDiv = styled.address`
color: #A9D5EA;
  display: flex;
  flex-direction: row;
  align-tems: center;
  padding: 120px;
  background: #330064;
  font-size: 1.125rem;
  @media (max-width: 619px) {
    padding: 60px;
  }
  @media (max-width: 429px) {
    padding: 40px;
  }
  @media (max-width: 359px) {
    padding: 40px 20px;
  }
`
const StyledImgDiv = styled.div`
  grid-column: 6/12;
  grid-row: 2/6;
  display: flex;
  justify-content: center;
`
const StyledFindDiv = styled.div`
  grid-column: 6/12;
  grid-row: 6/10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 120px;
  @media (max-width: 1199px) {
    grid-column: 5/12;
    grid-row: 5/9;
  }
  @media (max-width: 619px) {
    padding: 60px;
  }
  @media (max-width: 429px) {
    padding: 40px;
  }
  @media (max-width: 359px) {
    padding: 40px 20px;
  }
`

const StyledAddressDiv = styled.div`
  padding-bottom: 2rem;
  font-size: 1.125rem;
  color: #A9D5EA;
`

const StyledHeading = styled.h3`
  padding-bottom: 1.5rem;
  color: #A9D5EA;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;`


function Contact() {
  return (
    <>
    <section className="ourImpact">
      <PageHeader image={HeaderImage} title="Contact Us" subTitle="Let's hear from you." />
    

    <StyledMainWrapper>
      {/* <StyledMainGrid> */}
        <div className='contact-section'>
          <h2 className='contact-header'> CONTACT DETAILS</h2>
          <div className='contact-info'>
            <div>
              <h3>Easy to reach us</h3>
            <Email />
            <PhoneContact />
            </div>
            <div>
            <h3>Visit us</h3>
              <Address />
            </div>
          </div>

        </div>

  </StyledMainWrapper>
  </section>
  </>
  )
}

export default Contact
