import React from 'react';
import './HeroImpact.css';
import { Link } from 'react-router-dom';


function HeroImpact() {
  return (
    <section className="heroImpact" >
      <div className="heroImpact__contentContainer">
        <div className="heroImpact__content">
          <h1 style={{textShadow: '1px 1px #FFA500'}}>Our Impact</h1>
          <h2 style={{textShadow: '0.4px 0.5px #FFA500'}}>Inspiring greatness, one child at a time</h2>
          <Link className="heroImpact__callToAction" to="/about">Learn more</Link>
        </div>
      </div>
    </section>
  )
}

export default HeroImpact;
